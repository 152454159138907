import AuthHeader from '../components/structure/AuthHeader';

const Custom404 = () => (
  <>
    <AuthHeader />
    <div className="block mx-auto max-w-sm px-5 md:px-0">
      404 - Page non trouvée
    </div>
  </>
);

export default Custom404;
