import Head from 'next/head';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';

const AuthHeader = (props) => {
  const { t } = useTranslation();

  return (
    <header className="pb-4 pt-4 relative overflow-hidden">
      <Head>
        <title>{props.title || 'solucia.caarl.fr'}</title>
      </Head>
      <Image
        alt="logo solucia"
        src="/images/solucia_logo.png"
        width={500}
        height={135}
        className="block"
      />
      <div className="text-title">{t('main-title')}</div>
    </header>
  );
};

export default AuthHeader;
